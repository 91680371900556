import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllCustomers() {
      return new Promise((resolve, reject) => {
        axios
          .get('/customer/')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomer(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/customer/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCustomer(ctx, customer) {
      return new Promise((resolve, reject) => {
        axios
          .put('/customer/', customer)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateCustomer(ctx, customer) {
      return new Promise((resolve, reject) => {
        axios
          .post('/customer/', customer)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteCustomer(ctx, customerID) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/customer?customerID=${customerID}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEnquiry(ctx, folder) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/customer/enquiry/${folder}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEnquiry(ctx, enquiry) {
      return new Promise((resolve, reject) => {
        axios
          .post('/customer/enquiry', enquiry)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createEnquiry(ctx, enquiry) {
      return new Promise((resolve, reject) => {
        axios
          .put('/customer/enquiry', enquiry)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addReply(ctx, data) {
      const { emailID, reply } = data
      return new Promise((resolve, reject) => {
        axios
          .post(`/customer/enquiry/${emailID}`, reply)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadAttachment(ctx, attachments) {
      return new Promise((resolve, reject) => {
        axios
          .put('/customer/uploadenquiryfiles', attachments, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    downloadAttachment(ctx, file) {
      return new Promise((resolve, reject) => {
        axios({
          url: `/customer/enquiryfiles/${file}`, // File URL Goes Here
          method: 'GET',
          responseType: 'blob',
        })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
